import React from "react";
import { Route, Switch } from "react-router-dom";
import pageGroups from "src/visiblePageGroups";
import Page from "./Page";
import NotFound from "components/NotFound";

const Docs = () => (
  <Switch>
    {pageGroups.flatMap(({ group, pages }) =>
      pages.map(({ path, publishedYaml }) => (
        <Route key={path} path={path} exact>
          <Page
            title={group}
            links={pages}
            specUrl={`/static/media/${publishedYaml}`}
          />
        </Route>
      ))
    )}
    <Route path="*">
      <NotFound />
    </Route>
  </Switch>
);

export default Docs;
