/*
 * Configure page groups, pages, and yaml bundles here.
 *
 * Each object in the pageGroups array represents a grouping of spec pages
 * typically for a given app. Each group can have one or more pages within it.
 *
 * Each page object within a group should have:
 *
 * title: used in the card on the home page and nav in the doc page path
 * url: where the rendered docs page will be found
 * description: used in the card on the home page
 * sourceYaml: source yaml file from which to build the published yaml file
 * publishedYaml: file name to use for the compiled yaml file for this page
 * internal: optionally set to true if this should not be public
 *
 * The sourceYaml should be the primary yaml file for the bundle which contains
 * references to all of the other endpoint yaml files for that documentation
 * page. The publishedYaml should be the name of the generated yaml file that
 * will be built from the sources.
 *
 * sourceYaml is relative to ./src/specs/
 * publishedYaml is relative to ./public/static/media/
 *
 * This config will be used by scripts/bundle-specs.js to build the published
 * yaml, by the React app to generate the home page and docs pages, and by the
 * cypress tests to generate automated tests for all of the pages.
 */

const pageGroups = [
  {
    group: "Decipher APIs",
    pages: [
      {
        title: "Decipher REST API",
        path: "/docs/decipher/api",
        description: "Automate your surveys and access your survey data.",
        sourceYaml: "decipher/api.yaml",
        publishedYaml: "decipher-api.yaml",
      },
      {
        title: "Decipher Internal APIs",
        path: "/docs/decipher/internal",
        description:
          "Endpoints reserved for internal usage which may change " +
          "outside the normal versioning process.",
        sourceYaml: "decipher/internal.yaml",
        publishedYaml: "decipher-internal.yaml",
        internal: true,
      },
    ],
  },
  {
    group: "Sample Marketplace Partner Hub",
    pages: [
      {
        title: "Partner API Contract",
        path: "/docs/smp/contract",
        description:
          "Sample providers that wish to be added to the Forsta " +
          "Sample Marketplace must implement this API contract.",
        sourceYaml: "smp/contract.yaml",
        publishedYaml: "smp-contract.yaml",
      },
      {
        title: "Qualification List",
        path: "/docs/smp/qualification-list",
        description: "List of qualifications by locale.",
        sourceYaml: "smp/qualification-list.yaml",
        publishedYaml: "smp-qualification-list.yaml",
      },
      {
        title: "Partner Setup API",
        path: "/docs/smp/setup",
        description:
          "Endpoints used to manage vendor configurations within the " +
          "Sample Marketplace.",
        sourceYaml: "smp/setup.yaml",
        publishedYaml: "smp-setup.yaml",
      },
    ],
  },
  {
    group: "Panel Management",
    pages: [
      {
        title: "Integration API",
        path: "/docs/panelmanagement/integration/api",
        description: "API Integration for Panel Management.",
        sourceYaml: "panelmanagement/integration/main.yaml",
        publishedYaml: "pm-integration.yaml",
      },
      {
        title: "Decipher API",
        path: "/docs/panelmanagement/decipher/api",
        description: "Decipher API for Panel Management.",
        sourceYaml: "panelmanagement/decipher/main.yaml",
        publishedYaml: "pm-decipher.yaml",
        internal: true
      },
      {
        title: "Community API",
        path: "/docs/panelmanagement/community/api",
        description: "Community API for Panel Management.",
        sourceYaml: "panelmanagement/community/main.yaml",
        publishedYaml: "pm-community.yaml",
      },
      {
        title: "Report Service for Panel Management",
        path: "/docs/panelmanagement/panel-report-service/api",
        description: "Report Service that tracks feature usages for Panel Management.",
        sourceYaml: "panelmanagement/panel-report-service/main.yaml",
        publishedYaml: "pm-panel-report-service.yaml",
        internal: true,
      },
      {
        title: "Webhooks data",
        path: "/docs/panelmanagement/webhooks/api",
        description: "Data sent by webhooks from Panel Management.",
        sourceYaml: "panelmanagement/webhooks/main.yaml",
        publishedYaml: "pm-webhooks.yaml",
        internal: false,
      }
    ],
  },
  {
    group: "Survey Conversion",
    pages: [
      {
        title: "Survey Conversion",
        path: "/docs/converter/converter",
        description: "Convert to and from different survey formats",
        sourceYaml: "converter/converter.yaml",
        publishedYaml: "converter-converter.yaml",
        internal: true,
      },
    ],
  },
];

module.exports = pageGroups;
