import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import { color } from "stylist";

const StyledIcon = styled(Icon)`
  display: block;
`;

const StyledLink = styled(Link)`
  margin-top: 1rem;
  text-decoration: none;
  color: ${color("ocean")};
  display: block;
`;

const CardContainer = styled.div`
  height: 9rem;
  padding: 4rem 1.25rem 1.25rem;
  background-color: white;
  margin-bottom: 1rem;

  @media screen and (min-width: 710px) {
    margin-bottom: 0;
  }
`;

const Description = styled.p`
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${color("slate")};
  height: 3.25rem;
  overflow: hidden;
`;

const Card = ({ title, description, path }) => (
  <CardContainer>
    <StyledIcon />
    <StyledLink to={path}>{title}</StyledLink>
    <Description>{description}</Description>
  </CardContainer>
);

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
};

export default Card;
