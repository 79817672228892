import React from "react";
import { RedocStandalone } from "redoc";
import PropTypes from "prop-types";
import Header from "components/Header";
import Subnav from "./Subnav";
import styled from "@emotion/styled";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ScrollableContainer = styled.div`
  overflow-y: auto;
`;

const Page = ({ title, links, specUrl }) => (
  <Container>
    <Header title={title} />
    <Subnav links={links} />
    <ScrollableContainer>
      <RedocStandalone specUrl={specUrl} />
    </ScrollableContainer>
  </Container>
);

Page.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  specUrl: PropTypes.string,
};

export default Page;
