import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Theme } from "stylist";
import "./global.css";
import Home from "./components/Home";
import Docs from "./components/Docs";
import NotFound from "./components/NotFound";

const App = () => {
  return (
    <Theme theme="forsta">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/docs/*">
            <Docs />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </BrowserRouter>
    </Theme>
  );
};

export default App;
