import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { GlobalHeader, ForstaLogo, color, ChevronIcon } from "stylist";
import { Link } from "react-router-dom";

const StyledGlobalHeader = styled(GlobalHeader)`
  border: none;
  padding: 1em 1em 1em 0.5em;
`;

const StyledForstaLogo = styled(ForstaLogo)`
  height: 1em;
  margin-left: 1em;
`;

const StyledGlobalHeaderLogo = styled(GlobalHeader.Logo)`
  flex: none;
`;

const StyledChevronIcon = styled(ChevronIcon)`
  margin-left: 1rem;
`;

const PageTitle = styled.h2`
  color: ${color("pewter")};
  display: inline-block;
  flex: 1;
  font-size: 1.25rem;
  font-weight: 500;
  margin-left: 0.75rem;
  text-align: left;
`;

const Header = ({ title }) => (
  <StyledGlobalHeader>
    <GlobalHeader.Main>
      <Link to="/" title="Forsta API Docs Home">
        <StyledGlobalHeaderLogo renderLogo={() => <StyledForstaLogo />} />
      </Link>
      {title && (
        <>
          <StyledChevronIcon direction="right" />
          <PageTitle>{title}</PageTitle>
        </>
      )}
    </GlobalHeader.Main>
  </StyledGlobalHeader>
);

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
