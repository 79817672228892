import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Card from "./Card";

const Container = styled.div`
  max-width: 1000px;
  margin: 4rem auto 0;
`;

const Title = styled.h3`
  margin: 2.25rem 0 0;
  font-size: 1rem;
  font-weight: 500;
`;

const ThreeColumnGrid = styled.div`
  margin-top: 1.25rem;
  display: grid;
  width: 100%;
  column-gap: 1rem;
  row-gap: 1rem;

  grid-template-columns: 1fr;

  @media screen and (min-width: 710px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Group = ({ group, pages }) => (
  <Container>
    <Title>{group}</Title>
    <ThreeColumnGrid>
      {pages.map((page, i) => (
        <Card key={i} {...page} />
      ))}
    </ThreeColumnGrid>
  </Container>
);

Group.propTypes = {
  group: PropTypes.string,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      path: PropTypes.string,
    })
  ),
};

export default Group;
