import React from "react";

const Icon = (props) => (
  <svg
    {...props}
    width="32"
    height="40"
    viewBox="0 0 32 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 10.5L15 19.5V39.5L0 30.5V10.5Z" fill="#C4C4C4" />
    <path d="M32 10.5L17 19.5V39.5L32 30.5V10.5Z" fill="#C4C4C4" />
    <path d="M16 17.5L1 8.5L16 0L31 8.5L16 17.5Z" fill="#C4C4C4" />
  </svg>
);

export default Icon;
