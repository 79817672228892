import React from "react";
import styled from "@emotion/styled";
import { ForstaLogo, ButtonLink, color } from "stylist";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CenteredMessage = styled.div`
  text-align: center;
  max-width: 40rem;
  padding: 1rem;
`;

const Title = styled.h1`
  line-height: 1.5em;
  color: ${color("ink")};
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const StyledForstaLogo = styled(ForstaLogo)`
  height: 0.8em;
  margin-right: 1rem;
`;

const StyledButtonLink = styled(ButtonLink)`
  margin-top: 1.5rem;
  text-decoration: none;
`;

const NotFound = () => (
  <Container>
    <CenteredMessage>
      <Title>
        <StyledForstaLogo />
        <NoWrap>API Documentation</NoWrap>
      </Title>
      <h2>Page Not Found</h2>
      <p>We're sorry, we couldn't find the page you were looking for!</p>
      <p>
        Click the link below to return to the main page where you can browse all
        of the products for which we have API documentation available. If you
        still don't see what you're looking for, please reach out to support.
      </p>
      <StyledButtonLink kind="primary" to="/">
        Home
      </StyledButtonLink>
    </CenteredMessage>
  </Container>
);

export default NotFound;
