import React from "react";
import styled from "@emotion/styled";
import { color } from "stylist";

const Background = styled.div`
  background-color: ${color("ink")};
`;

const Content = styled.div`
  margin 0 auto;
  max-width: 1000px;
  text-align: left;
  padding: 8.75rem 1rem 4rem;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 1rem;
  color: ${color("white")};
  font-weight: 500;
`;

const BigText = styled.h2`
  margin: 1.25rem 0 0;
  padding: 0;
  font-size: 3rem;
  color: ${color("white")};
  font-weight: 300;
`;

const SmallText = styled.p`
  padding: 0;
  margin: 1.5rem 0 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: ${color("white")};
  max-width: 500px;
`;

const Hero = () => (
  <Background>
    <Content>
      <Title>Forsta APIs</Title>
      <BigText>Connect</BigText>
      <SmallText>
        Explore the APIs for our products, be creative, and invent your own ways
        of interacting with your data. We can&apos;t wait to see what you come
        up with.
      </SmallText>
    </Content>
  </Background>
);

export default Hero;
