import React from "react";
import Header from "components/Header";
import Hero from "./Hero";
import styled from "@emotion/styled";
import { color } from "stylist";
import pageGroups from "src/visiblePageGroups";
import Group from "./Group";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${color("offWhite")};
`;

const ScrollableContainer = styled.div`
  overflow-y: auto;
`;

const Main = styled.div`
  flex-grow: 1;
  padding 0 1rem;
  padding-bottom: 5rem;
`;

const Home = () => {
  return (
    <Container>
      <Header />
      <ScrollableContainer>
        <Hero />
        <Main>
          {pageGroups.map((group, i) => (
            <Group key={i} {...group} />
          ))}
        </Main>
      </ScrollableContainer>
    </Container>
  );
};

export default Home;
