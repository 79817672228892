import pageGroups from "./pageGroups";

const hideInternalPages = process.env.REACT_APP_ENV !== "internal";

export const removeInternalPages = (groups) =>
  groups
    .map(({ group, pages }) => ({
      group,
      pages: pages.filter((page) => !page.internal),
    }))
    .filter(({ pages }) => pages.length);

export default hideInternalPages ? removeInternalPages(pageGroups) : pageGroups;
