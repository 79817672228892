import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "stylist";
import styled from "@emotion/styled";

const StyledTabs = styled(Tabs)`
  margin-top: 0;
  margin-left: 2.5rem;
`;

const StyledTabsItem = styled(Tabs.Item)`
  text-decoration: none;
`;

const Subnav = ({ links }) =>
  links && (
    <StyledTabs>
      {links.map(({ title, path }) => (
        <StyledTabsItem to={path} key={path}>
          {title}
        </StyledTabsItem>
      ))}
    </StyledTabs>
  );

Subnav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.String,
      path: PropTypes.String,
    })
  ),
};

export default Subnav;
